import { Analytics } from '@vercel/analytics/react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import 'tailwindcss/tailwind.css';

import '../style/plyr-custom.css';
import '../style/plyr.css';
import '../style/styles.css';
import '../style/x-pyxis.scss';

const queryClient = new QueryClient();

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Head>

      <Script
        id="Cookiebot"
        src="https://consent.cookiebot.com/uc.js"
        data-cbid="e28f9653-5a8d-478d-8764-010b3403cad0"
        data-blockingmode="auto"
      />
      <Component {...pageProps} />

      <Analytics />
    </QueryClientProvider>
  );
}

export default MyApp;
